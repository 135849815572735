body {
  margin: 0;
  background-color: black;
  color: #fff;
  vertical-align: top;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: lighter;
}
  
.mainReact {
  margin: 0;
  flex-direction: column;
  height: 100%;
}

.center {
  text-align: center;
}