.Footer {
    width: 100%;
    height: 1.3em;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-around;
}

.Footer > div {
    display: block;
    position: relative;
    text-align: center;
}

.Footer > div > p {
    margin: 0;
    padding: 0%;
}

.link {
    color: gray;
    text-decoration: none;
}