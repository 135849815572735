.mapouter {
    width: 100%;
    text-align: center;
    align-items: center;
}

.gmapDiv {
    width: 90%;
    height: 60vh;
    margin: 0 auto;
}

.gmapDiv > iframe {
    width: 100%;
    height: 100%;
}


@media only screen and (min-width: 1260px) {
    .gmapDiv {
        height: 75vh;
    }
}