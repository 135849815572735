.leftAligned {
    text-align: left;
    padding: 0em 1em 0em 2em;
}

.leftAligned > p {
    color: lightgray
}

.leftAligned > p > a {
    color: white;
    text-decoration: none;
}

.Greet {
    margin-top: 2em;
    color: white;
    text-decoration: none;
}

.Greet > * {
    margin: 0;
}