.Navbar {
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 2em;
    display: flex;
    border-bottom: 1px solid gray;
    height: 3.5em;
    z-index: 3;
    background-color: black;
}

.offset {
    height: 3.5em;
}

.Navbar > div {
    float: left;
    display: inline;
    color: white;
    text-align: center;
    text-decoration: none;
    border: 1px solid gray;
    margin: 0px;
    width: 33%;
    max-width: 10em;
}

.Navbar > div > a {
    display: block;
    color: white;
    text-align: center;
    padding: 1em 0em;
    text-decoration: none;
}

.Navbar > div > li:hover {
    background-color: #444;
}