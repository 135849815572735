ol, ul {
    padding-left: 30px;
  }
  .board-row {
    display: flex;
    justify-content: center;
    height: 6em;
  }
 
  .square {
    background: #000;
    width: 20%;
    height: 100%;
    margin-right: -1px;
    margin-top: -1px;
    max-width: 6em;
    text-align: center;
    display: grid;
  }

  .button {
    background: #000;
    color: #FFF;
    width: 100%;
    height: 100%;
    margin-right: -1px;
    margin-top: -1px;
    overflow-wrap:break-word;
    grid-area: 1 / 1;
    padding: 0;
    border: 1px solid white;
  }

  .overlayX {
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
    color: #FFF;
    font-size: 5em;
    font-weight: bold;
    z-index: 2;
    grid-area: 1 / 1;
  }
  
  .square:hover {
    background-color: #333;
  }
  