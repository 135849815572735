.Terminbuchung {
    margin-left: 1em;
}

.Terminbuchung > form > hr {
    width: 90%;
    margin-left: 0;
}

.InputLabel {
    font-size: 1.2em;
    grid-column: 0;
    display: block;
}

.InputElement {
    grid-column: 1;
    display: inline;
    margin-bottom: 1em;
}

.formHeader {
    width: 90%;
}

.SelectElement {
    height: 2em;
    background-color: black;
    color: white;
    font-size: 1.3em;
    margin: 0;
    width: 100%;
}

.Submit {
    padding: 0.5em;
    color: white;
    background-color: black;
    border-radius: 0.5em;
    border: 1px solid white;
    font-size: 1em;
}

.Link {
    padding: 0.5em;
    color: white;
    background-color: black;
    border-radius: 0.5em;
    border: 1px solid white;
    font-size: 1em;
    display: block;
    width: fit-content;
    text-decoration: none;
}

.Friseur {
    text-decoration: underline;
}

@media only screen and (min-width: 480px) {

}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1024px) {

}

@media only screen and (min-width: 1260px) {
    .formHeader {
        display: flex;
        align-items: center;
    }

    .SelectElement {
        margin-left: 1em;
    }
}